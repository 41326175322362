<template>
  <div style="width: 100%">
    <ckeditor
      @ready="(editor) => eventReadyEditor(editor, this)"
      :editor="editor"
      :config="editorConfig"
      v-model="inputVal"
      :disabled="disabled"
      ref="editorCustom"
    ></ckeditor>
  </div>
</template>

<script>
import ClassicEditor from "@ckeditor/ckeditor5-editor-classic/src/classiceditor";
import EssentialsPlugin from "@ckeditor/ckeditor5-essentials/src/essentials";
import BoldPlugin from "@ckeditor/ckeditor5-basic-styles/src/bold";
import ItalicPlugin from "@ckeditor/ckeditor5-basic-styles/src/italic";
import LinkPlugin from "@ckeditor/ckeditor5-link/src/link";
import ParagraphPlugin from "@ckeditor/ckeditor5-paragraph/src/paragraph";
import HeadingPlugin from "@ckeditor/ckeditor5-heading/src/heading";
import FontPlugin from "@ckeditor/ckeditor5-font/src/font";
// import ListPlugin from '@ckeditor/ckeditor5-list/src/list';
import ListStyle from "@ckeditor/ckeditor5-list/src/liststyle";
import TablePlugin from "@ckeditor/ckeditor5-table/src/table";
import AlignmentPlugin from "@ckeditor/ckeditor5-alignment/src/alignment";
import TableToolbar from "@ckeditor/ckeditor5-table/src/tabletoolbar";
import TableProperties from "@ckeditor/ckeditor5-table/src/tableproperties";
import TableCellProperties from "@ckeditor/ckeditor5-table/src/tablecellproperties";
import Clipboard from "@ckeditor/ckeditor5-clipboard/src/clipboard";
import PasteFromOffice from "@ckeditor/ckeditor5-paste-from-office/src/pastefromoffice";
import AutoLink from "@ckeditor/ckeditor5-link/src/autolink";
import Underline from "@ckeditor/ckeditor5-basic-styles/src/underline";

// import ImageToolbar from '@ckeditor/ckeditor5-image/src/imagetoolbar';
import EasyImage from "@ckeditor/ckeditor5-easy-image/src/easyimage";
import Image from "@ckeditor/ckeditor5-image/src/image";
import SimpleUploadAdapter from "@ckeditor/ckeditor5-upload/src/adapters/simpleuploadadapter";
import ImageResize from "@ckeditor/ckeditor5-image/src/imageresize";

// import ImageCaptionPlugin from '@ckeditor/ckeditor5-image/src/imagecaption';
import ImageStylePlugin from "@ckeditor/ckeditor5-image/src/imagestyle";
import ImageToolbarPlugin from "@ckeditor/ckeditor5-image/src/imagetoolbar";
import ImageUploadPlugin from "@ckeditor/ckeditor5-image/src/imageupload";

import Ls from "@/core/services/jwt.service.js";
const TOKEN_PREP = Ls.getToken();

export default {
  name: "Editor",
  props: {
    value: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    minHeight: {
      type: [Number, String],
      default: 200,
    },
    placeholder: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      editor: ClassicEditor,
      editorConfig: {
        placeholder: "",
        plugins: [
          EssentialsPlugin,
          ParagraphPlugin,
          BoldPlugin,
          ItalicPlugin,
          Underline,
          LinkPlugin,
          AutoLink,
          HeadingPlugin,
          FontPlugin,
          ListStyle,
          TablePlugin,
          AlignmentPlugin,
          TableToolbar,
          TableProperties,
          TableCellProperties,
          Clipboard,
          PasteFromOffice,
          EasyImage,
          SimpleUploadAdapter,
          Image,
          ImageResize,
          ImageStylePlugin,
          ImageToolbarPlugin,
          ImageUploadPlugin,
        ],
        toolbar: {
          items: [
            "heading",
            "|",
            "bold",
            "italic",
            "underline",
            "|",
            "link",
            "|",
            "undo",
            "redo",
            "|",
            "fontSize",
            "fontFamily",
            "fontColor",
            "fontBackgroundColor",
            "|",
            "bulletedList",
            "numberedList",
            "|",
            "insertTable",
            "|",
            "alignment:left",
            "alignment:right",
            "alignment:center",
            "alignment:justify",
            "|",
            "imageUpload",
          ],
          shouldNotGroupWhenFull: true,
        },
        simpleUpload: {
          // The URL that the images are uploaded to.
          // process.env.VUE_APP_BASE_URL
          // uploadUrl: 'https://prep.vn.test/api/prep-app/element/upload-image-ckeditor',
          uploadUrl:
            process.env.VUE_APP_BASE_URL +
            "prep-app/element/upload-image-ckeditor",

          // Enable the XMLHttpRequest.withCredentials property.
          withCredentials: false,

          // Headers sent along with the XMLHttpRequest to the upload server.
          headers: {
            // 'X-CSRF-TOKEN': 'CSRF-Token',
            Authorization: "Bearer " + TOKEN_PREP,
          },
        },
        image: {
          toolbar: [
            "imageStyle:full",
            "imageStyle:side",
            "imageStyle:alignLeft",
            "imageStyle:alignCenter",
            "imageStyle:alignRight",
          ],
          styles: [
            // This option is equal to the situation when no style is applied.
            "full",
            // This represents a side image.
            "side",
            // This style represents an image aligned to the left.
            "alignLeft",
            // This style represents a centered image.
            "alignCenter",
            // This style represents an image aligned to the right.,
            "alignRight",
          ],
        },
        table: {
          contentToolbar: [
            "tableColumn",
            "tableRow",
            "mergeTableCells",
            "tableProperties",
            "tableCellProperties",
          ],
        },
        link: {
          decorators: {
            openInNewTab: {
              mode: "manual",
              label: "Open in a new tab",
              attributes: {
                target: "_blank",
              },
            },
          },
        },
      },
    };
  },
  watch: {
    placeholder(val) {
      console.log("placeholder", val);
      this.editorConfig.placeholder = val;
    },
  },
  computed: {
    inputVal: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
  created() {
    this.editorConfig.placeholder = this.placeholder;
  },
  methods: {
    eventReadyEditor(editor, vm) {
      this.customHeight(editor, vm.minHeight);
    },
    customHeight: (editor, minHeight) => {
      let cpEditor = editor;
      editor.editing.view.change((writer) => {
        writer.setStyle( 'min-height', minHeight+'px', cpEditor.editing.view.document.getRoot() );
      });
    }
  }
};
</script>

<style></style>
